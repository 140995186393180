import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Speech = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `FMCorreia`
  


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Speaking" />

    <section className="speaking-first-section">
      <div className="speaking-first-div">
        <h1>Speaking</h1>
        <span>By Filipe Correia</span>
      </div>
    </section>

    <div className="first-section global-wrapper">
      <p>I love to talk about sustainability and informatics.</p>
      <p></p>
      <p>If you would like me to speak at your event, <Link to="/#get-in-touch">feel free to get in touch</Link>.</p>
      <br/>
      <div className="divider"></div>

      <p><b>These are some of my public presentations:</b></p>

      <div className="list-speaking">
            <div>
                <h3>Os Jovens, o Ambiente e a COVID-19 (Youth, the Environment and COVID-19)</h3>
                <p>In 2021, I was invited to talk about the consequences of the COVID-19 pandemic on the environment. I participated in the "21 às 21" show by Coopernico.
                The speech and many other resources can be found <a href="/covid-ambiente" >on the official page of the event (PT)</a>.</p>
            </div>
            <div>
                <h3>On.FM Presentation at IST</h3>
                <p>My team was invited to present our investigation of radio frequencies at the "Instituto Superior Técnico".
                Our work and research can be found <Link to="/projects/onfm">here</Link>.</p>
            </div>

            <br></br>

      </div>


     </div>

    </Layout>
  )
}

export default Speech

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
